/* stylelint-disable selector-max-compound-selectors */

.Accordion {
  details {
    summary {
      @apply relative cursor-pointer list-none flex items-center justify-between;

      &::after {
        @apply block;
        content: url('~@assets/icons/icon-plus.svg');
      }

      &::-webkit-details-marker {
        display: none;
      }
    }

    &[open] {
      summary {
        &::after {
          content: url('~@assets/icons/icon-minus.svg');
        }
      }
    }
  }

  details[open] summary ~ * {
    display: block;
    overflow: hidden;
    height: auto;
    animation: slideDown 0.4s ease-in;
  }

  /* Slide up animation when closing */
  details.closing summary ~ * {
    height: auto;
    animation: slideUp 0.4s ease-out;
    overflow: hidden;
  }

  /* Slide down animation */
  @keyframes slideDown {
    0% {
      max-height: 0;
    }

    100% {
      max-height: 500px;
    }
  }

  @keyframes slideUp {
    0% {
      max-height: 500px;
    }

    100% {
      max-height: 0;
    }
  }
}
