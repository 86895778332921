/* stylelint-disable */

.gtranslate {
  .gtranslate_wrapper {
    @apply relative h-full flex items-center;

    #gt_float_wrapper {
      @apply flex;
    }

    .gt_float_switcher {
      @apply bg-transparent font-sans shadow-none;
    }

    .gt_float_switcher .gt-selected {
      @apply bg-transparent p-0;
    }

    .gt_float_switcher .gt-selected .gt-current-lang {
      @apply text-white p-0;
    }

    .gt_float_switcher
      .gt-selected
      .gt-current-lang
      span.gt_float_switcher-arrow {
      @apply invert contrast-[4];
    }

    .gt_float_switcher .gt_options {
      @apply absolute top-full left-0 bg-primary-light2 shadow-lg min-w-[200px] translate-y-s12;
    }

    .gt_float_switcher .gt_options a {
      @apply text-black;
    }
  }

  &--footer {
    .gtranslate_wrapper {
      @apply justify-end;

      .gt_float_switcher .gt-selected .gt-current-lang {
        @apply text-black;
      }

      .gt_float_switcher .gt_options {
        @apply right-0 max-w-[200px] ml-auto;
      }

      .gt_float_switcher
        .gt-selected
        .gt-current-lang
        span.gt_float_switcher-arrow {
        @apply contrast-0 invert-0;
      }
    }
  }
}
