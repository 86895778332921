@layer utilities {
  .btn {
    @apply inline-block font-medium border px-s16 py-s12;
  }

  .btn-primary {
    @apply border-transparent bg-primary text-white;
  }

  .btn-outline {
    @apply border-primary text-primary;
  }

  .btn-white {
    @apply border-transparent text-primary bg-white;
  }
}

.btn-livestream {
  @apply btn;

  &[data-is-active='true'] {
    @apply btn-primary;
  }

  @apply btn-white;
}
