:root {
  --swiper-pagination-color: #ad223b;
}

:root :where(a:where(:not(.wp-element-button))) {
  text-decoration: inherit;
}

// html {
//   scroll-behavior: smooth;
// }

body[data-scroll-disable='true'] {
  @apply overflow-hidden;
}

.wrapper {
  @apply grid grid-cols-4 gap-y-s32 gap-x-s8 max-w-[1110px] mx-auto items-center px-s16 lg:px-s24 lg:grid-cols-12 @lg:gap-y-0 lg:gap-x-s24;
}

.content-wrapper {
  @apply @lg:min-h-[700px];
}

.is-active {
  @apply block;
}

.is-slide-active {
  @apply opacity-100 visible;
}

.is-hidden {
  @apply hidden;
}

.is-open {
  @apply rotate-180 transition-transform;
}

//overlap-settings
.is-overlap-50 {
  @apply lg:-my-[50px];

  div.acf-block-preview & {
    @apply my-0;
  }
}

@layer utilities {
  .font-thin {
    font-variation-settings: 'wght' 100;
  }

  .font-extralight {
    font-variation-settings: 'wght' 200;
  }

  .font-light {
    font-variation-settings: 'wght' 300;
  }

  .font-normal {
    font-variation-settings: 'wght' 400;
  }

  .font-medium {
    font-variation-settings: 'wght' 500;
  }

  .font-semibold {
    font-variation-settings: 'wght' 600;
  }

  .font-bold {
    font-variation-settings: 'wght' 700;
  }

  .font-extrabold {
    font-variation-settings: 'wght' 800;
  }

  .font-black {
    font-variation-settings: 'wght' 900;
  }
}

.wysiwyg {
  ul {
    @apply flex flex-col gap-y-s16 mt-s24;
  }

  ul li {
    @apply flex gap-x-s16;

    &::before {
      content: url('~@assets/icons/icon-check.svg');
    }
  }

  p:not(:last-of-type) {
    @apply mb-s24;
  }

  a {
    @apply text-primary;
  }
}

.wysiwyg-dark {
  a {
    @apply text-white underline;
  }
}

.loader {
  @apply border-4 border-white border-t-4 border-t-primary rounded-full w-s32 h-s32 mx-auto animate-spin;
}

.clip-pl {
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
}

.clip-pl2 {
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

.clip-pl3 {
  clip-path: polygon(100% 0, 0 0, 100% 100%);
}

.clip-pl4 {
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
}
