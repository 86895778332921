.article-content-home,
.article-content-inner,
.article-content-post {
  > :not(section) {
    @apply flex flex-col max-w-[996px] mx-auto px-s24 @lg:px-0;
  }

  > ul,
  > ul ul {
    @apply list-disc pl-s48 text-md mb-s24 text-camel-dark;
  }

  > p {
    @apply block text-md mb-s24 text-camel-dark;

    &:empty {
      @apply hidden;
    }

    &:last-of-type {
      @apply mb-0;
    }
  }

  > ul a,
  > p a {
    @apply text-primary inline;
  }

  > blockquote {
    @apply block border-l-4 border-primary pl-s16 mb-s24 text-md text-camel-dark;
  }

  .wp-block-heading {
    @apply block mb-s12 text-primary font-semibold;
  }

  h1.wp-block-heading {
    @apply text-h1;
  }

  h2.wp-block-heading {
    @apply text-h2;
  }

  h3.wp-block-heading {
    @apply text-h3;
  }

  h4.wp-block-heading {
    @apply text-h4;
  }

  h5.wp-block-heading {
    @apply text-h5;
  }

  h6.wp-block-heading {
    @apply text-h6;
  }
}

.article-content-inner {
  &:has(> section:first-child) {
    @apply pt-0;
  }

  &:has(> section:last-child) {
    @apply pb-0;
  }

  p + section,
  ul + section,
  blockquote + section {
    @apply mt-s80;
  }

  p + .wp-block-heading,
  ul + .wp-block-heading {
    @apply mt-s48;
  }
}
