/* stylelint-disable selector-max-compound-selectors */

.wpcf7-form {
  @apply w-full;

  fieldset {
    @apply flex flex-col lg:flex-row flex-auto gap-x-s24 gap-y-s24 mb-s24;

    > * {
      @apply w-full;
    }
  }

  br {
    @apply hidden;
  }

  label {
    @apply hidden;
  }

  span {
    @apply block text-center text-sm text-dark-grey;
  }

  input,
  textarea {
    @apply font-sans text-base w-full border border-primary-light bg-white text-dark-grey p-s12 placeholder:text-dark-grey;
  }

  textarea {
    @apply mt-s16 mb-s32;
  }

  input[type='submit'] {
    @apply block border-transparent bg-primary text-white font-semibold mt-s16 w-auto mx-auto px-s32 duration-300 hover:bg-black hover:text-white hover:border-transparent cursor-pointer;
  }
}

.plan-your-visit-form {
  .wpcf7-form {
    input[type='submit'] {
      @apply mt-s32;
    }
  }

  .disclaimer {
    @apply mt-s8;
  }
}
